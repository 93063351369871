.mainHeat{
    padding: 40px;
}

.searchData{
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
}

.main{
    padding: 10px 0;
}

.heatMain{
    padding: 10px;
    /* width: 900px; */
}

.tableContent{
    max-width: 1507px;
    overflow-y: auto;
}

.selectorMain{
    display: flex;
}
.selector{
    padding: 16px 28px;
    margin: 11px;
    border-radius: 5px;
    background-color: #e9e9e9;
    cursor: pointer;
}

.selectedData{
    background-color: #8d2626;
    color: white;
}
.expandContainer{
   position: relative !important; 
}

.borderTop {
    border-top: 1px solid black;
}

.borderRight {
    border-right: 1px solid black;
}

.borderBottom{
    border-bottom: 1px solid black;
}

.borderLeft{
    border-left: 1px solid black;
}

.proceed{
    position: sticky !important;
    bottom: 0  !important;
    width: 56%  !important;
    left: 50%  !important;
    top: 50%  !important;
    transform: translate(-50%, -50%)  !important;
    background-color: #8C2E32  !important;
    padding: 9px  !important;
    font-size: 16px  !important;
    padding-bottom: 38px  !important;
    color: white  !important;
    margin: 10px;
}

.clearBtn{
    color: blue;
    cursor: pointer;
}

.filterCss{
    width: 400px;
    padding: 0px 20px 10px;
    background: #8080800a;
    margin: 10px 0;
    border-radius: 12px;
}

.modalStyles {
    border-radius: 20px 20px 0 0;
    width: 1000px !important;
  }