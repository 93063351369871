.searchData{
    margin: 20px 0;
    box-sizing: border-box;
    display: flex;
}

.main{
    padding: 10px 0;
}

.secondRow{
    display: flex;
    gap: 20px;
}

.filterCss{
    width: 400px;
    padding: 0px 20px 10px;
    background: #8080800a;
    margin: 10px 0;
    border-radius: 12px;
}
.indicatorCss{
    width: 400px;
    padding: 0px 20px 10px;
    background: #8080800a;
    margin: 10px 0;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
}

.rangeFields{
    display: flex;
    gap: 10px;
}

.indicatortext{
    display:flex; 
    gap:20px; 
    align-items:center;

    & p{
        margin: 0;
    }
}

.indicatorStyles{
    padding:2px 7px;
    width: 3px; 
    height: 10px;
    border-radius:20px; 
    width:3px;
}

.green{
    background-color: #DAEBDB !important;


}

.red{
    background-color: #FCE6E8 !important;
}

.yellow{
    background-color: #FCEECC;
}