.cssClass{
  /* white-space: nowrap;  */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    margin: 3px;
    display: block;
}

.productHead{
  padding: 20px;
  background: rgba(233, 233, 233, 0.46);
}

.textData{
  padding: 20px 8px 0;
  /* margin: 10px; */
  box-sizing: border-box;
  /* background: #C4E6FF; */
  border-radius: 5px;
}

.btnsgreen{
  width: 41px;
  height: 35px;
  background: #00AC1C;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
}

.cssClassMob{
  display: none;
}

@media screen and (max-width: 1400px) and (min-width: 992px) {
  .btnsgreen{
    width: 35px;
    height: 32px;
    background: #00AC1C;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
  }

  .spanBtn{
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .productHead{
    display: none;
  }
  .cssClassMob{
    display: block;
  }

  .spanBtn{
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}