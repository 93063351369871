.mainContainer {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.formData {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rowContainer {
    display: flex;
    justify-content: space-between;
}

.fieldListing {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.fieldIcons {
    background-color: #000000;
    border-radius: 5px;
    padding: 10px;
    width: 30px;
    text-align: center;
    margin-right: 10px;
}

.field {
    font-size: 17px;
    margin: 0;
    font-weight: 700;
}

.descriptions {
    font-size: 16px;
    margin: 0;
}

.cancelNext {
    text-align: right;
}

.totalDetails {
    display: flex;
    justify-content: space-between;
}

.totals {
    margin: 10px;
}

.prodDetails {
    padding: 3px;
    /* background: #B2D7F1; */
    border: 1px solid #cdc2c2c2;
    border-radius: 5px;
    margin: 7px 20px;
    text-align: center;
    /* font-weight: bold; */
    text-overflow: ellipsis;
    font-size: 13px;
}

.deleteBox {
    margin: 15px;
    cursor: pointer;
    width: max-content;
}

.subtotalView {
    padding: 20px 35px 0;
    background: #F6EEEF;
}

.headtext {
    color: rgba(0, 0, 0, 0.7);
}

.searchData {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.names {
    display: none;
}



@media screen and (max-width: 768px) and (min-width: 320px) {
    .formData {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .prodDetails {
        display: flex;
        justify-content: space-between;
        border: none;
        margin: 5px;
    }

    .names {
        display: block;
    }

    .prodData {
        box-shadow: 0px 5px 4px #cbcbcb;
        margin: 10px;
        border-radius: 8px;
    }

    .totalDetails{
        flex-direction: column;
    }

    .totals{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }

    .totalAmt{
        margin-top: 20px;
        text-align: left !important;
    }

    .amt{
        text-align: left !important;
    }
}