.demodata {
  max-width: 1400px;
  font-size: 22px;
  margin: 32px auto;
}

.topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapperContainer {
    display: block;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;
}
.Container{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 17px;
}
.contentWrapper {
    width: 30%;
    box-sizing: border-box;
    margin: 15px 0;
 
}

.contentWrapper > p:nth-child(2) {
    margin: 0px;
  }

  .EditButton{
    position: absolute;
    right: 10px;
    top: 10px;
  }