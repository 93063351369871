
.tablestatuslist{
    display: flex;
    gap: 5px;
}

.boxStyling{
    font-size: 14px;
    font-weight: 700;
    /* background-color: #ccc; */
    width: 30px;
    /* height: 30px; */
    padding: 5px 2px;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
}

.statusTrue{
    background-color: #DAEBDB ;
    color: #1A6B1F;
}

.statusFalse{
    background-color: #505050;
    color: #cccccc;
}

.mainTitle{
    font-size: 18px;
    font-weight: 100;
    margin: 0;
}

.Conatiner{
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 500;
}

.description{
    font-size: 12px;
    font-weight: 500;
    color: #49454F;
}