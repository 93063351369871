.mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.searchData{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}