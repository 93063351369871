.mainContainer{
    padding: 10px;
    background-color: #e7e7e7;
    margin: 10px;
    width: 30%;
}

.flexContainer{
    display: flex;
    flex-wrap: wrap;
}

.flexSpace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.basicDetailFormMain{
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.basicsForm{
    width: 50%;
    padding: 20px;
}

.mainHeatGen{
    box-shadow: 0px -1px 9px #c9b9b9;
    margin: 53px 10px 40px;
    box-sizing: border-box;
    padding: 20px !important;
    border-radius: 12px;
    background-color: #e1e1e140;
}

.tables{
    display: flex;
}

.left{
    border-left: 1px solid grey;
}

.right{
    border-right: 1px solid grey;
}

.top{
    border-top: 1px solid grey;
}

.bottom{
    border-bottom: 1px solid grey;
}

.widthTwentyPercent{
    box-sizing: border-box;
}

.widthHundred{
    width: 100%;
}

.widthTwenty{
    width: 10%;
}

.widthT{
    width: 20%;
}

.padding{
    padding: 15px;
    box-sizing: border-box;
}


.ingotView{
    width: 90%;
    margin: 10px 0;
}