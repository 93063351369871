.headerTitle {
    text-align: center;
}

.POMain{
    box-shadow: 0px 1px 3px #bbadad;
    max-width: 1300px;
    margin: 20px auto;
    box-sizing: border-box;
    padding: 40px 20px;
    border-radius: 12px;
}

.mainContainer {
    border: 1px solid lightgray;
    margin: 0 auto;
    width: 96%;
}

.InputFiledCon {
    display: flex;
}

.leftCon {
    width: 50%;


}

.rightCon {
    width: 50%;
}

.inputFieldCon {
    width: 80%;
}

.inputFieldConTwo {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.purchaseOrderLeft {
    padding: 10px;
    width: 50%;
}

.purchaseOrderRight {
 
    width: 50%;
}

.CountryFields{
    height: 100%;
}

.innerFields{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.demoBorder:last-child {
    border-bottom: 1px solid lightgray;
  }

.boderBottom{
    border-bottom: 1px solid lightgray;
}

.borderLeft{
    border-left: 1px solid lightgray;
   }

.borderRight{
 border-right: 1px solid lightgray;
}

.borderTop{
    border-top: 1px solid lightgray;
}

.borderBox{
    box-sizing: border-box;
}

.paddingten{
    padding: 10px;
}


@media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .POMain{
        box-shadow: 0px 1px 3px #bbadad;
        max-width: 1092px;
        margin: 20px auto;
        box-sizing: border-box;
        padding: 40px 6px;
        border-radius: 12px;
    }

    .mainContainer {
        border: 1px solid lightgray;
        margin: 0 auto;
        width: 98%;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 990px) {
    .POMain{
        box-shadow: 0px 1px 3px #bbadad;
        max-width: 900px;
        margin: 20px auto;
        box-sizing: border-box;
        padding: 40px 6px;
        border-radius: 12px;
    }

    .mainContainer {
        border: 1px solid lightgray;
        margin: 0 auto;
        width: 98%;
    }
}