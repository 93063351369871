
.wrapperContainer {
    display: block;
    max-width: 1400px;
    margin: 20px auto;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 30px;
}
.Container{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 17px;
}
.contentWrapper {
    width: 30%;
    box-sizing: border-box;
    margin: 15px 0;
}

.contentWrapper > p:nth-child(2) {
    margin: 0px;
  }

  .demodata{
    max-width: 1400px;
    font-size: 22px;
    margin: 32px auto;
  }

  .topContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .headerTitle {
    text-align: center;
}

.POMain{
    box-shadow: 0px 1px 3px #bbadad;
    margin: 20px;
    box-sizing: border-box;
    padding: 40px 20px;
    border-radius: 12px;
}

.mainContainer {
    border: 1px solid lightgray;
    margin: 0 auto;
    width: 96%;
}

.InputFiledCon {
    display: flex;
}

.leftCon {
    width: 50%;


}

.rightCon {
    width: 50%;
}

.inputFieldCon {
    width: 80%;
}

.inputFieldConTwo {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.purchaseOrderLeft {
    padding: 10px;
    width: 50%;
}

.purchaseOrderRight {
 
    width: 50%;
}

.CountryFields{
    height: 100%;
}

.innerFields{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.demoBorder:last-child {
    border-bottom: 1px solid lightgray;
  }

.boderBottom{
    border-bottom: 1px solid lightgray;
}

.borderLeft{
    border-left: 1px solid lightgray;
   }

.borderRight{
 border-right: 1px solid lightgray;
}

.borderTop{
    border-top: 1px solid lightgray;
}

.borderBox{
    box-sizing: border-box;
}

.paddingten{
    padding: 10px;
}