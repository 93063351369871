.mainData{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: -1px 4px 7px rgba(140, 46, 50, 0.17);
    border-radius: 12px;
    width: 60%;
    margin: 20px auto;
    cursor: pointer;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
    
    .mainData{
        width: 80%;
        margin: 10px auto;
    }
}