.mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.searchData{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.containerMain{
    padding: 30px;
    background: white;
    max-width: 1100px;
    margin: 0 auto;
    /* box-shadow: 0px 0px 1px #a3a1a1; */
    border-radius: 12px;
}

.lightBg{
    background: #c6999b0d;
    padding: 20px;
    margin: 11px 0 20px;
    border-radius: 12px;
}

.boxaddress{
    padding: 20px;
    box-sizing: border-box;
    background: #c5abbf14;
    border-radius: 10px;
    margin-bottom: 20px;
}

.flexData{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}