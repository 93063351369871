.cssClass{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin: 10px;
  }
  
  .productHead{
    padding: 10px 20px;
    background: rgba(233, 233, 233, 0.46);
  }
  
  .textData{
    padding: 20px 20px 0;
    margin: 10px;
    box-sizing: border-box;
    background: #C4E6FF;
    border-radius: 5px;
  }
  
  .btnsgreen{
    /* margin-top: 15px; */
    width: 41px;
    height: 35px;
    background: #00AC1C;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
  }

  .prodDetails{
    padding: 13px;
    background: #B2D7F1;
    border-radius: 5px;
    margin: 7px 20px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btns{
  background: #c32929d1 !important;
    color: white !important;
    width: 154px !important;
    height: 40px !important;
}

.totalDetails{
  display: flex;
  justify-content: space-between;
}

.subtotalView{
  padding: 35px 35px;
  background: #F6EEEF;
  box-shadow: 0px 0px 4px #dbabab94;
  margin: 44px 10px;
}

.headtext{
  color: rgba(0, 0, 0, 0.7);
}

.totals{
  margin: 10px;
}

.deleteBox{
  width: max-content;
  margin-top: 10px;
}