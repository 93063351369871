.tables{
    display: flex;
}

.left{
    border-left: 1px solid #e9e9e9;
}

.right{
    border-right: 1px solid #e9e9e9;
}

.top{
    border-top: 1px solid #e9e9e9;
}

.bottom{
    border-bottom: 1px solid #e9e9e9;
}

.widthTwentyPercent{
    box-sizing: border-box;
}

.widthHundred{
    width: 26%;
}

.widthTwenty{
    width: 10%;
}

.widthT{
    width: 20%;
}

.padding{
    padding: 20px;
}


.mainHeatG{
    background-color: #e1e1e140;
    border-radius: 12px;
    box-shadow: 0 -1px 9px #c9b9b9;
    box-sizing: border-box;
    margin-top: 53px;
    padding: 20px!important;
}