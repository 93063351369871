.wrapperContainer {
    display: block;
    max-width: 1400px;
    margin: 0 auto 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}


.Container {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 17px;
}

.contentWrapper {
    width: 30%;
    box-sizing: border-box;
    margin: 5px 0;
}

.contentWrapper>p:nth-child(2) {
    margin: 0px;
}

.demodata {
    max-width: 1400px;
    margin: 32px auto;
}

.mainAddress{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.mainAdr{
    padding: 10px;
    margin: 10px;
    box-shadow: 0px 1px 11px #b9b0b0c9;
    border-radius: 12px;
    min-width: 200px;
}

.addSupplier{
    padding: 20px;
    box-shadow: 2px 2px 5px #cdcaca;
    border-radius: 11px;
}

.btnDownload{  
    padding: 10px 20px;
    border: 1px solid grey;
    width: fit-content;
    border-radius: 12px;
    cursor: pointer;
}