.cssClass{
  /* white-space: nowrap; 
  overflow: hidden; */
  text-overflow: ellipsis; 
  margin: 10px;
}

.productHead{
  padding: 20px;
  background: rgba(233, 233, 233, 0.46);
}

.textData{
  padding: 20px 20px 0;
  margin: 10px;
  box-sizing: border-box;
  /* background: #C4E6FF; */
  background: #8C2E3217;
  border-radius: 5px;
}

.btnsgreen{
  width: 41px;
  height: 35px;
  background: #00AC1C;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
}

.containerInputesfielsds{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.feildswithname{
  width: calc(100% / 6);
}