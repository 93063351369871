.mainContainer{
    /* padding: 0 20px; */
}

.titleHeading{
    display: flex;
    gap: 25px;
    background: #8C2E3212;
    padding: 0 20px;
}

