.mainData{
  padding: 20px;
}

.cssClass{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    margin: 10px;
  }
  
  .productHead{
    padding: 20px;
    background: rgba(233, 233, 233, 0.46);
  }
  
  .textData{
    padding: 20px 20px 0;
    margin: 10px;
    box-sizing: border-box;
    background: #C4E6FF;
    border-radius: 5px;
  }
  
  .btnsgreen{
    width: 41px;
    height: 35px;
    background: #00AC1C;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
  }

  .mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.searchData{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.nameList{
  /* padding: 20px 20px 38px 48px; */
  padding: 20px 0 38px 0;
}

.linkBox{
  display: flex;
  justify-content: space-between;
  width: 76%;
  border: 2px solid grey;
  margin-bottom: 7px;
  padding: 10px;
  border-radius: 18px;
}

.prodDetails{
  word-break: break-all;
}