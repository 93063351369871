.mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.searchData{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.containerMain{
    padding: 30px;
    background: white;
    max-width: 50%;
    margin: 20px auto;
    box-shadow: 3px 3px 9px #a3a1a1;
    border-radius: 12px;
}

.productsIndicator{
    width: 400px;
    padding: 0px 20px 10px;
    background: #8080800a;
    margin: 10px 0;
    border-radius: 12px;
    /* display: flex;
    justify-content: space-between; */
}

.indicatortext{
    display:flex; 
    gap:20px; 
    align-items:center;

    & p{
        margin: 0;
    }
}

.indicatorStyles{
    padding:2px 7px;
    width: 3px; 
    height: 10px;
    border-radius:20px; 
    width:3px;
}

.green{
    background-color: #DAEBDB !important;


}

.red{
    background-color: #FCE6E8 !important;
}