.textCss {
    color: #8C2E32;
    font-size: 20px;
    font-weight: 400;
    margin-top: 70px;
    margin-right: 20px;
}

.boxContainer {
    display: flex;
}

.box {
    border-radius: 10px;
    background: #FFF;
    padding: 50px;
    margin: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(140, 46, 50, 0.15);
}