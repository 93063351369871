.mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.searchTab{
    margin-bottom: 20px;
}

/* .Indent_PO{
    background-color: #DAEBDB !important;
}

.Shipment{
    background-color: #D0C8F9 !important;
}
.Doc_Present{
    background-color: #FBC7C9 !important;
}

.Bank_Payment{
    background-color: #E2FBFE !important;
}

.BOE_{
    background-color: #F9CFDE !important;
}

.Out_of_Charge{
    background-color:#FEE1A3 !important;
}

.Detention{
    background-color: #FCD1BE !important;
}

.IGM_Date{
    background-color: #D4DBFA !important;
} */

.tablestatuslist{
    display: flex;
    gap: 5px;
}

.boxStyling{
    font-size: 14px;
    font-weight: 700;
    /* background-color: #ccc; */
    width: 30px;
    /* height: 30px; */
    padding: 5px 2px;
    text-align: center;
    border-radius: 25px;
}

.statusTrue{
    background-color: #DAEBDB ;
    color: #1A6B1F;
}

.statusFalse{
    background-color: #505050;
    color: #cccccc;
}

.fullnamebox{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & h4{
        width: 150px;
        margin: 10px 0;
    }
}

.StatusIndicator{
    display: flex;
    gap: 40px;

    

    & span{
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 25px;
        /* background-color: #505050; */
    }
}

.name{
    display: flex;
    gap: 10px;
}

.green{
    background-color: #AFF1B8;
}

.dark{
    background-color: #505050;
}