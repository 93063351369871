.mainContainer{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.searchData{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.containerMain{
    padding: 30px;
    background: white;
    max-width: 95%;
    margin: 20px auto;
    /* box-shadow: 3px 3px 9px #a3a1a1; */
    border-radius: 12px;
}

.Div{
    /* width: 300px; */
    display: flex;
    justify-content: space-between;
}

.formDetails{
    width: 30%;
    /* height: 88vh; */
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 5px;
}

.nameList{
    display: flex;
    gap: 25px;
}

.prodDetails{
    width: 100%;
}