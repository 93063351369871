.app p,
.app div {
    box-sizing: border-box;
    margin: 0px

}
.app{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 40px;
}


.widthHund{
    width: 100%;
}

.widthFifty{
    width: 50%;
}

.widthTwfive{
    width: 25%;
}

.width{
    width: 12.5%;
}

.widthTwenty{
    width: 25%;
}

.widthEight {
    width: 75%;
}

.flex {
    display: flex;
}




.justifyContentcenter {
    justify-content: center;
}

.alignItems {
    align-items: center;
}

.textAlignCenter{
    text-align: center;
}

.borderTop {
    border-top: 1px solid black;
}

.borderRight {
    border-right: 1px solid black;
}

.borderBottom{
    border-bottom: 1px solid black;
}

.borderLeft{
    border-left: 1px solid black;
}


.paddingTop{
    padding-top: 10px
}

.paddingRight{
    padding-right: 10px;
}

.paddingBottom{
    padding-bottom: 10px;
}

.paddingLeft {
    padding-left: 10px;
}


.marginZero{
    margin: 0px;
}

.paddingTwelve{
    padding: 13px 0px
}


.borderred{
    border : 1px solid red;
}


.headlinepad{
    padding : 12px 0px ;
}

.fontfivesixty{
font-weight: 580;
}

.fontsixhundred{
font-weight: 600;
}


.fontBold{
    font-weight: bold;
}

.paddingthirty{
padding-top: 30px;
}