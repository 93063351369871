.content{
    /* width: 85%;
    margin-left: auto; */
    width: 80%;
}

.menuRoute{
    display: none;
    padding: 10px;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
    .content{
        width: 100%;
    }

    .menuRoute{
        display: block;
        padding: 10px;
        width: 45px;
    }

    .menus{
        width: 40px;
    }
}