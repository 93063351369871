.logoDiv {
    width: 100%;
    margin: 10px 0;
}
.positionSide{
    width: 290px;
    position: relative;
}
.images {
    width: 100%;
}

.mainContainer {
    padding: 20px 10px;
}

.users {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 233px;
}

.close{
    display: none;
}

.lists{
    /* margin-bottom: 200px; */

    & li:last-child{
        margin-bottom: 100px !important;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
    .sideHead {
        position: fixed;
        z-index: 12;
        background: white;
        height: 100%;
        width: 250px;
        top: 0;
    }
    
    .images{
        width: 200px;
    }

    .close{
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .logoDiv{
        position: relative;
    }
}